module.exports = {
  siteTitle: 'Jermaine Davis',
  siteDescription: `The resume of a nerd/geardhead who happens to be a husband and father.`,
  authorName: 'Jermaine Davis',
  twitterUsername: 'phdRealSoon',
  githubUsername: 'mainephd',
  authorAvatar: '/images/me.jpg',
  authorDescription: `Experienced Information Technology Architect with a demonstrated history of working in the retail industry. Strong information technology professional skilled in Java, Go, Node, Python, Agile, XP, Automation, CI, CD, PaaS, IaaS, Cloudfoundry and GCP.`,
    skills: [
    {
      name: 'Containers',
      level: 80
    },
    {
      name: 'Go',
      level: 80
    },
    {
      name: 'NodeJs',
      level: 80
    },
    {
      name: 'Python',
      level: 80
    },
    {
      name: 'React',
      level: 70
    },
    {
      name: 'Git',
      level: 80
    },
    {
      name: 'Cloud Architecture',
      level: 90
    },
    {
      name: 'Java',
      level: 70
    },
    {
      name: 'Leadership',
      level: 80
    },
    {
      name: 'Kubernetes',
      level: 50
    },
    {
      name: 'Cloud Foundry',
      level: 70
    }
  ],
  jobs: [
    {
      company: "The Home Depot",
      begin: {
        month: 'oct',
        year: '2018'
      },
      duration: null,
      occupation: "Distinguished Engineer",
      description: "I am responsible for the technical direction of solutions in Supply Chain."
  
    },
    {
      company: "The Home Depot",
      begin: {
        month: 'jun',
        year: '2013'
      },
      duration: '5 years 6 months',
      occupation: "Software Engineer Principal/Architect IT",
      description: "Responsible for the architecture of In Store Back Office Systems."
    },
    {
      company: "The Home Depot",
      begin: {
        month: 'aug',
        year: '2011'
      },
      duration: '1 year 11 months',
      occupation: "Lead IT Developer",
      description: "Led projects to deliver mobile solutions for In Store Back Office Systems."
    },
    {
      company: "The Home Depot",
      begin: {
        month: 'may',
        year: '2007'
      },
      duration: '4 years 6 months',
      occupation: "Senior IT Developer",
      description: "Developed and supported solutions for In Store Back Office Systems."
    }

  ],
  social: {
    twitter: "https://twitter.com/phdRealSoon",
    linkedin: "https://www.linkedin.com/in/jermaineadavis/",
    github: "https://github.com/mainephd",
    email: "jermaine.davisa@gmail.com"
  },
  siteUrl: 'https://jermainedavis.me',
  pathPrefix: '/', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-132810316-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
  headerLinks: [
    {
      label: 'Jermaine Davis',
      url: '/',
    }
  ]
}